import { ref } from "vue";
import QuoteService from "@/services/QuoteService";
import router from "@/router";
import RfqOptionsInterface from "@/types/quote-details/RfqOptionsInterface";

export default () => {
  const initOptionsLoading = ref(false);
  const optionsLoading = ref(false);
  const quoteOptions = ref<RfqOptionsInterface>({} as RfqOptionsInterface);
  const fetchQuoteV2Options = (withLoading = true) => {
    if (withLoading) {
      initOptionsLoading.value = true;
    }
    optionsLoading.value = true;
    return QuoteService.getQuoteV2Options(
      `${router.currentRoute.value.params.id}`
    )
      .then((res) => {
        const oldQuoteOptions = JSON.parse(JSON.stringify(quoteOptions.value));
        const result = res.data.data;

        if (oldQuoteOptions?.totalPartProduction) {
          result.totalPartProduction.price =
            oldQuoteOptions.totalPartProduction.price;
          result.totalQualityControl.price =
            oldQuoteOptions.totalQualityControl.price;
          result.totalShipping.price = oldQuoteOptions.totalShipping.price;
          result.totalPrice = oldQuoteOptions.totalPrice;
          result.discount.value = oldQuoteOptions.discount;
        }

        quoteOptions.value = result;
      })
      .finally(() => {
        if (withLoading) {
          initOptionsLoading.value = false;
        }
        optionsLoading.value = false;
      });
  };
  return {
    optionsLoading,
    initOptionsLoading,
    quoteOptions,
    fetchQuoteV2Options,
  };
};
